const projetsNav = [
  {
    categoryId: "restaurant",
    navTitle: "RESTAURANT BAR |",
  },
  {
    categoryId: "club",
    navTitle: "CLUB |",
  },
  {
    categoryId: "evenementiel",
    navTitle: "EVENEMENTIEL |",
  },
  {
    categoryId: "retail",
    navTitle: "RETAIL |",
  },
  {
    categoryId: "residentiel",
    navTitle: "RESIDENTIEL |",
  },
  {
    categoryId: "office",
    navTitle: "OFFICE |",
  },
];

export default projetsNav;
